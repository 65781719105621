import React, { Component } from "react";
import { config } from "_helpers/config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { docsActions } from "_actions/docs.actions";
import { Link } from "react-router-dom";
import { formatDate } from "_helpers/helpers";
import { Tab, Row, Col, NavItem, Nav } from "react-bootstrap";
import { MerchandiseOrder } from "Docs/Forms/MerchandiseOrderForm";
import { docsService } from "_services/docs.service";
import { chatActions } from "_actions/chat.actions";
import { isMobileOnly } from "react-device-detect";
import { Pdf } from "../Docs/Components";
import TimeOffRequest from "Docs/Forms/TimeOffRequest";
import { PaycheckDistribution }  from "Docs/Forms/PaycheckDistribution";
import { CandidateInfoPage } from "Docs/Forms/CandidateInfoPage";
import { W4 } from "Docs/Forms/W4";
import Payroll from "Docs/Forms/Payroll";
import ErrorBoundary from "_components/ErrorBoundary";
import { selectCurrentUserPermissions } from "_helpers/reduxSelector";
import InterviewForm from "../InterviewForm";
import EquipmentResponsibilityForm from "Docs/Forms/EquipmentResponsibilityForm";
import DocStatusTag from "_components/utils/DocStatusTag";
import VerbalWarningAck from "Docs/Forms/VerbalWarningAck/VerbalWarningAck";
import Certificates from "Certificates/Certificates";
import BeneficiaryForm from "Docs/Forms/BeneficiaryForm";

const DOC_LOCK_STATUS=3
const OTHER_DOCUMENT_ID=3

const DocumentTypes={
  Onboarding:1,
  Rehire:2,
  Other:3,
  GISS_Onboarding:4,
  GISS_Rehire:5,
}

class DocsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminMode: false,
      view_doc: false,
      outerTab: "onboarding",
      tab: "list",
      doc: {
        new: null,
        id: null,
        slug: "merchandise-order-form",
      },
      mobile: {
        screen: "sidebar",
        enabled: false,
        closeConfirmationModal: false,
      },
      pdf_view: false,
      loading: false,
    };
    this._compareFn = this._compareFn.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.generateDocList = this.generateDocList.bind(this);
    this.createDocument = this.createDocument.bind(this);
    this.viewDoc = this.viewDoc.bind(this);
    this.viewList = this.viewList.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.tabChangeOuter = this.tabChangeOuter.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.getPdf = this.getPdf.bind(this);
    this.togglePdf = this.togglePdf.bind(this);
    this.findActiveDoc = this.findActiveDoc.bind(this);
  }

  componentDidUpdate() {
    let { tab } = this.state;
    if (tab === "document") {
      document.body.classList.add("document-active");
    } else {
      document.body.classList.remove("document-active");
    }
  }
  async componentDidMount() {
    try {
      let { doc, category, hash, id } = this.props.match.params;
      this.props.dispatch(chatActions.getConversationSettings());
      this.findActiveDoc(id);
      // id && this.props.dispatch(docsActions.getDoc(id)).then(res => this.setState({doc:res}))

      this.props.match.path === "/profile/:hash/docs/:category/:doc/:id" && // single_doc
        this.setState({
          outerTab: "other",
          adminMode: true,
          doc: { ...this.state.doc, id, slug: doc },
          view_doc: true,
        });

      this.props.dispatch(docsActions.getDocCategory());
      this.props.dispatch(docsActions.getDocTypes());
      // @param type 'Onboarding = 1,Rehire = 2,Other = 3'
      this.props.dispatch(docsActions.getCategoryDocs(3));

      this.props.dispatch(docsActions.getFormOptions());

      this.handleResize();
      window.addEventListener("resize", this.handleResize);

      // Get Extra doc if user docs are locked

      const currentPageIsSettings = this.props.match.path === "/settings"
      if(currentPageIsSettings){
        hash = this.props.user.profile.data.hash
      }
      const onboardingDocsAreLocked = this?.props?.user?.profile?.data?.docs_status === DOC_LOCK_STATUS
      const rehireDocsAreLocked = this?.props?.user?.profile?.data?.rehire_docs_status === DOC_LOCK_STATUS

      // if (currentPageIsSettings) {
      //   if (onboardingDocsAreLocked) {
      //     await this.props.dispatch(docsActions.getExtraDocs({
      //       docs_category: DocumentTypes.Onboarding
      //     }));
      //   }
      //   if (rehireDocsAreLocked) {
      //     await this.props.dispatch(docsActions.getExtraDocs({
      //       docs_category: DocumentTypes.Rehire
      //     }));
      //   }
      // }

      this.props.match.path === "/profile/:hash" || this.props.match.path === "/profile/:hash/docs/:category/:doc/:id" || this.props.match.path === "settings" // single_doc
        ? this.props.dispatch(docsActions.getUserDocs(hash))
        : this.props.dispatch(docsActions.getDocs());
    } catch (error) { }

    const urlParams = new URLSearchParams(this.props.location?.search);
    const slug = urlParams?.get("slug");

    if (slug === "acknowledge-form" || slug === "warning-management") {
      this.tabChangeOuter("other");

      const docId = Number(urlParams?.get("doc_id"));
      const docTypeId = Number(urlParams?.get("doc_type_id"));

      if (!isNaN(docId)) {
        const docSlug =
          slug === "acknowledge-form"
            ? "equipment-responsibility-form"
            : "warning-management";

        this.setState({
          doc: {
            slug: docSlug,
            id: docId,
            docs_type_id: docTypeId
          },
          tab: "document",
        });
      }
    }
  }

  // find doc based on id(url param) from docs list
  findActiveDoc(id) {
    const docs = (() => this.props.docs && this.props.docs.userDocs)();
    const doc = docs.find((doc) => doc.id === id);
    doc && this.setState({ doc });
  }

  handleResize() {
    clearTimeout(this.resizeTimeOut);
    this.resizeTimeOut = setTimeout(() => {
      if (isMobileOnly && window.screen.width < 768) {
        this.setState({ mobile: { ...this.state.mobile, enabled: true } });
      } else {
        this.setState({ mobile: { ...this.state.mobile, enabled: false } });
      }
    }, 100);
  }

  componentWillReceiveProps(props) {
    let { id } = this.props.match.params;
    this.findActiveDoc(id);
    let loggedInUser = null;
    this.props.user && this.props.user.profile && (loggedInUser = this.props.user.profile.data);
    loggedInUser && this.setState({ adminMode: loggedInUser.is_admin > 0 });
    // single_doc
    this.props.match.path === "/profile/:hash/docs/:category/:doc/:id" &&
      props.docs.userDocs.length > 0 &&
      this.setState({ tab: "document" });
  }
  // Compare function
  _compareFn(a, b, type, by) {
    if (type === "docs_name") {
      if (by === "asc") {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return -1;
        } else if (name_1 > name_2) {
          return 1;
        } else return 0;
      } else {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return 1;
        } else if (name_1 > name_2) {
          return -1;
        } else return 0;
      }
    } else if (type === "updated_at") {
      if (by === "asc") {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return -1;
        } else if (date_1 > date_2) {
          return 1;
        } else return 0;
      } else {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return 1;
        } else if (date_1 > date_2) {
          return -1;
        } else return 0;
      }
    }else if(type === "created_at"){
      if (by === "asc") {
        let date_1 = new Date(a.created_at),
          date_2 = new Date(b.created_at);
        if (date_1 < date_2) {
          return -1;
        } else if (date_1 > date_2) {
          return 1;
        } else return 0;
      } else {
        let date_1 = new Date(a.created_at),
          date_2 = new Date(b.created_at);
        if (date_1 < date_2) {
          return 1;
        } else if (date_1 > date_2) {
          return -1;
        } else return 0;
      }
    }
    return 0;
  }
  // Handle sorting in table
  sortHandler(type) {
    let documents = this.props.docs.userDocs;
    documents.sort();
    switch (type) {
      case "docs_name":
        if (this.state.order_doc === "asc") {
          documents.sort((a, b) => this._compareFn(a, b, "docs_name", "asc"));
          this.setState({ documents, order_doc: "desc" });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, "docs_name", "desc"));
          this.setState({ documents, order_doc: "asc" });
        }
        break;
      case "updated_at":
        if (this.state.order_at === "asc") {
          documents.sort((a, b) => this._compareFn(a, b, "updated_at", "asc"));
          this.setState({ documents, order_at: "desc" });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, "updated_at", "desc"));
          this.setState({ documents, order_at: "asc" });
        }
        break;
      case "created_at":
        if (this.state.created_at === "asc") {
          documents.sort((a, b) => this._compareFn(a, b, "created_at", "asc"));
          this.setState({ documents, created_at: "desc" });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, "created_at", "desc"));
          this.setState({ documents, created_at: "asc" });
        }
        break;
      default:
        return this.state.documents;
    }
  }
  tabChange(tab) {
    this.setState({ tab }, () => {
      let { doc, category, hash, id } = this.props.match.params;
      this.props.match.path === "/profile/:hash" || this.props.match.path === "/profile/:hash/docs/:category/:doc/:id" // single_doc
        ? this.props.dispatch(docsActions.getUserDocs(hash))
        : this.props.dispatch(docsActions.getDocs());
      if (this.props.match.path === "/profile/:hash/docs/:category/:doc/:id") {
        this.props.match.path = "/profile/:hash";
      }
    });
  }
  tabChangeOuter(outerTab) {
    this.setState({ outerTab });
  }
  createDocument() {
    this.setState({
      loading: true
    });
    let { doc, category, hash, id } = this.props.match.params;
    let item = this.props.docs.category_docs[this.state.doc.new]; // index from dropdown
    docsService
      .createDocument(item.docs_type_id, item.docs_category_id, this.state.adminMode ? hash : null)
      .then((res) => {
        this.props.dispatch(docsActions.getDocs()).then((response) => {
          this.setState({ doc: { ...this.state.doc, id: res.data.id, docs_type_id: res?.data?.docs_type?.id }, loading: false }, () => {            
            this.tabChange("document");
          });
        });
      })
      .catch((err) => {})
  }
  // https://fireapp.wrg-apps.com/docs/pdf/merchandise-order-form?id=185&uid=0907e6f18771b2119fb114c4c2ae537c&signature=53d177aaf9ab1f7a751ac5b4a53812da
  generateDocList(list, type, other, rehire, giss) {
    // debugger
    
    const currentPageIsSettings = this.props.match.path === "/settings";
    let { doc, category, id } = this.props.match.params;
    let hash = (() => {
      let hash;
      this.props.match.path === "/settings"
        ? this.props.user && this.props.user.profile && (hash = this.props.user.profile.data.hash)
        : (hash = this.props.match.params.hash);
      return hash;
    })();
    let uid, signature;
    let empty_list = [],
      docs_list;
    let shouldLockDocs;

    if (currentPageIsSettings){
      if (type === DocumentTypes.Rehire || type === DocumentTypes.GISS_Rehire) {
        shouldLockDocs = this?.props?.user?.profile?.data?.rehire_docs_status === DOC_LOCK_STATUS
      }
      if (type === DocumentTypes.Onboarding || type === DocumentTypes.GISS_Onboarding) {
        shouldLockDocs = this?.props?.user?.profile?.data?.docs_status === DOC_LOCK_STATUS
      }
    }


    this.props.user.profile && this.props.user.profile.data && (uid = this.props.user.profile.data.hash); // the user who logged in
    this.props.chat && this.props.chat.settings.signature && (signature = this.props.chat.settings.signature);

    let selectedUser = (() => this.props.user && this.props.user.items && this.props.user.items.data)();
    let loggedInUser = (() => this.props.user && this.props.user.profile && this.props.user.profile.data)();
    let filteredList = list;
    if (type === DocumentTypes.Rehire || type === DocumentTypes.GISS_Rehire) {
      filteredList = list.filter((doc) => (doc.docs_categories_id == 2 || doc.docs_categories_id == 5));
    } else if (type === DocumentTypes.Onboarding || type === DocumentTypes.GISS_Onboarding) {
      filteredList = list.filter((doc) => (doc.docs_categories_id == 1 || doc.docs_categories_id == 4));
    } else {
      filteredList = list.filter((doc) => doc.docs_categories_id == type);
    }
    if (this.props.isDocsLoading) {
      empty_list = <Loader />
    } else if (loggedInUser) {
      (filteredList.length === 0 && type === 1) || type === 4
        ? (empty_list = (
            <p className="doc-list-empty">
              {this.state.adminMode && selectedUser.hash !== loggedInUser.hash ? (
                <strong>{selectedUser.full_name}</strong>
              ) : (
                "You"
              )}{" "}
              haven't submitted any Onboarding Documents
            </p>
          ))
        : type === 3 || type === 6
        ? (empty_list = (
            <p className="doc-list-empty">
              {this.state.adminMode && selectedUser.hash !== loggedInUser.hash ? (
                <strong>{selectedUser.full_name}</strong>
              ) : (
                "You"
              )}{" "}
              haven't added any Other Documents
            </p>
          ))
        : (empty_list = (
            <p className="doc-list-empty">
              {this.state.adminMode && selectedUser.hash !== loggedInUser.hash ? (
                <strong>{selectedUser.full_name}</strong>
              ) : (
                "You"
              )}{" "}
              haven't added any Rehire Documents
            </p>
          ));
    }

    docs_list = empty_list;

    filteredList.length > 0 &&
      (docs_list = filteredList.map((doc, index) => {

        let docStatus = ""
        const docStatusColor = doc.tor_docs_status === 4 ? "#3f7e39" :"#c9c188"
        
        switch (doc?.tor_docs_status) {
          case 1:
            docStatus = "Not Approved"
            break;
          case 2:
            docStatus = "Submitted"
            break;
            case 3:
            docStatus = "In review"
            break;
            case 4:
            docStatus = "Approved"
            break;
            case 5:
            docStatus = "Not filled"
            break;
          default:
            docStatus = ""
            break;
        }


        return (
          <div
            className="applicant_item list_item"
            key={index}
          >
            <div className="homeEmp_list">
              <div className="basic_info unavailable_employe">
                <div className="item_cell">
                  {shouldLockDocs && doc?.docs_type?.is_lockable ? (
                    <i class="fas fa-lock text-red doc-list-img"></i>
                  ) : (
                    <img
                      className="doc-list-img"
                      src={`${config.assetUrl}icons/doc-check-circle.svg`}
                      alt=""
                    />
                  )}
                  <span>
                    {doc.doc_type_name}{" "}
                    {!!(doc.docs_type_id === 23) && <DocStatusTag
                      status={docStatus}
                      bgColor={docStatusColor}
                      fontColor={doc.tor_docs_status === 4 ? "#fff" : ""}
                    />}
                  </span>
                </div>
                <div className="item_cell">
                  <span>
                    {formatDate(doc.created_at)}
                  </span>
                </div>

                <div className="item_cell">
                  <span>
                  <span>
                    {doc.docs_categories_id === 3
                      ? doc.docs_type_id === 23 && doc.tor_docs_status === 4
                        ? formatDate(doc.approved_date)
                        : '-'
                      : formatDate(doc.updated_at)}
                  </span>
                  </span>
                </div>

                <div className="item_cell  doc_download">
                  {/* Download PDF */}
                  {this.state.adminMode && (
                    doc.docs_type.id !== 29 && <span className={`download-complete`}>
                      <a
                        href={`${config.baseUrl}docs/pdf/${doc.slug}?id=${doc.id}&uid=${uid}&signature=${signature}`}
                        target="_blank"
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-download.svg`}
                          className="closeIco downloadIco"
                          alt=""
                        />
                      </a>
                    </span>
                  )}
                  {other ? (
                    <span>
                      <button
                        className="button plain"
                        onClick={() => this.viewDoc(doc)}
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </button>
                    </span>
                  ) : rehire ? (
                    <span>
                      {this.state.adminMode ? (
                        <Link to={`/docs/${hash}/${doc.slug}?rehire=true`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      ) : (
                        <Link to={`/dashboard/docs/${doc.slug}?rehire=true`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      )}
                    </span>
                  ) : (
                    <span>
                      {this.state.adminMode ? (
                        <Link to={`/docs/${hash}/${doc.slug}?rehire=false`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      ) : (
                        <Link to={`/dashboard/docs/${doc.slug}?rehire=false`}>
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </Link>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }));

    return docs_list;
  }
  selectHandler(e) {
    let slug = this.props.docs.category_docs[e.target.value] ? this.props.docs.category_docs[e.target.value].slug : ""; // eg: merchandise order form
    let doc = this.state.doc;
    this.setState({
      doc: { ...doc, new: e.target.value, slug }, // index of item in array - this.props.docs.category_docs
    });
  }
  viewDoc(doc) {
    // debugger
    this.setState({
      doc,
      view_doc: true,
      tab: "document",
    });
  }
  viewList() {
    this.setState({
      doc: { ...this.state.doc, id: null },
      view_doc: false,
      tab: "list",
      pdf_view: false,
    });
  }

  getPdf(doc) {
    let sampleUid = this.props.user.profile ? this.props.user.profile.data.hash : "";
    let params = {
      id: doc.id,
      uid: sampleUid, // the user who logged in
      signature: this.props.chat.settings.signature,
    };
    // setting up url for pdf
    let url = new URL(`${config.apiUrl}/docs/pdf/${doc.slug}`);
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    return <Pdf className="other-pdf" url={url.href} lastPage={false} rehire={false} giss={false} />;
  }

  togglePdf(flag) {
    this.setState({ pdf_view: !flag });
  }

  createDownloadUrl = () => {
    let url = new URL(`${config.apiUrl}/docs/pdf/${this.state.doc.slug}`);
    let uid, signature;
    this.props.user.profile && this.props.user.profile.data && (uid = this.props.user.profile.data.hash || ""); // the user who logged in
    this.props.chat && this.props.chat.settings.signature && (signature = this.props.chat.settings.signature || "");
    let id = this.state.doc.id;
    let params = {
      id,
      uid,
      signature,
    };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    return url.href;
  };

  render() {
    let documents = this.props.docs.userDocs;
    let docTypes = this.props.docs.types;
    let categories = this.props.docs.categories;
    let category_docs = this.props.docs.category_docs;
    let selectedUser = null;
    this.props.user && this.props.user.items && (selectedUser = this.props.user.items.data);
    let { outerTab } = this.state;
    const permissions = (() =>
      this.props.permission && this.props.permission.permissions && this.props.permission.permissions.data)();
    return (
      <div className="documents-list user-docs">
        {/* <!--enable this tab contents corresponding to the tab clicked--> */}
        <Tab.Container id="tabs-with-dropdown" activeKey={outerTab}>
          {/* Tabs */}
          <Row className="clearfix">
            <Col sm={12}>
              <div className="custom-tabs">
                <Nav bsStyle="tabs">
                  <NavItem
                    eventKey="onboarding"
                    className="custom-tab"
                    onClick={() => this.tabChangeOuter("onboarding")}
                  >
                    <i className="fa fa-file"></i>
                    Onboarding <span>Documents</span>
                  </NavItem>
                  <NavItem eventKey="rehire" className="custom-tab" onClick={() => this.tabChangeOuter("rehire")}>
                    <i className="fa fa-file"></i>
                    Rehire <span>Documents</span>
                  </NavItem>
                  <NavItem eventKey="other" className="custom-tab" onClick={() => this.tabChangeOuter("other")}>
                    <i className="fa fa-file-alt"></i>
                    Other <span>Documents</span>
                  </NavItem>
                  {this?.props?.currentUserPermissions?.includes("Manage Interview Forms") && (
                    <NavItem
                      eventKey='interviewForms'
                      className='custom-tab'
                      onClick={() => this.tabChangeOuter("interviewForms")}>
                      <i className="fa fa-clipboard-list"></i>
                      Interview Forms
                    </NavItem>
                  )}
                  <NavItem
                    eventKey='certificates'
                    className='custom-tab'
                    onClick={() => this.tabChangeOuter("certificates")}>
                    <i className="fa fa-clipboard-list"></i>
                    Certificates
                  </NavItem>
                </Nav>
              </div>
            </Col>
            {/* Content */}
            <Col sm={12}>
              <div className="custom-tab-content">
                <Tab.Content animation>
                  {/* Onboarding DOCUMENTS */}
                  <Tab.Pane eventKey="onboarding">
                    <div className="appln_list_header">
                      <div className="list_item_head" onClick={() => this.sortHandler("docs_name")}>
                        <span className="sort-titles">Name</span>
                        <span className={`${this.state.order_doc === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                      </div>
                      <div className="list_item_head appln_date" onClick={() => this.sortHandler("created_at")}>
                        <span className="sort-titles">Date Created</span>
                        <span className={`${this.state.created_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                      </div>
                      <div className="list_item_head appln_date" onClick={() => this.sortHandler("updated_at")}>
                        <span className="sort-titles">Date Updated</span>
                        <span className={`${this.state.order_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                      </div>
                      
                      <div className="list_item_head"></div>
                    </div>

                    <div className="profile-docs-list">
                    {selectedUser &&
                      this.generateDocList(
                        documents,
                        (selectedUser.user_type_id === 11 || selectedUser.user_type_id === 12)  ? 4 : 1,
                        false,
                        false,
                        selectedUser.user_type_id === 11 || selectedUser.user_type_id === 12
                      )}
                    </div>
                  </Tab.Pane>

                  {/* Rehire DOCUMENTS */}
                  <Tab.Pane eventKey="rehire">
                    <div className="appln_list_header">
                      <div className="list_item_head" onClick={() => this.sortHandler("docs_name")}>
                        <span className="sort-titles">Name</span>
                        <span className={`${this.state.order_doc === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                      </div>
                      
                      <div className="list_item_head appln_date" onClick={() => this.sortHandler("created_at")}>
                        <span className="sort-titles">Date Created</span>
                        <span className={`${this.state.created_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                      </div>
                      <div className="list_item_head appln_date" onClick={() => this.sortHandler("updated_at")}>
                        <span className="sort-titles">Date Updated</span>
                        <span className={`${this.state.order_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                      </div>
                      
                      <div className="list_item_head"></div>
                    </div>
                    {selectedUser &&
                      this.generateDocList(
                        documents,
                        (selectedUser.user_type_id === 11 ||  selectedUser.user_type_id === 12) ? 5 : 2,
                        false,
                        true,
                        selectedUser.user_type_id === 11 || selectedUser.user_type_id === 12
                      )}
                  </Tab.Pane>

                  {/* Other Documents */}
                  <Tab.Pane eventKey="other">
                    <section className="content onboarding-docs">
                      {/* LIST */}
                      {this.state.tab === "list"  && (
                        <section className="list">
                          {this.props.match.path.includes("settings") && <p className="height-none button-container">
                            <button className="button"
                              onClick={() => this.tabChange("new")}
                              >
                              Add New Document
                            </button>
                          </p>}
                          {
                            !this.props.isDocsLoading && <div className="appln_list_header">
                            <div className="list_item_head" onClick={() => this.sortHandler("docs_name")}>
                              <span className="sort-titles">Name</span>
                              <span className={`${this.state.order_doc === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                            </div>

                            <div className="list_item_head appln_date" onClick={() => this.sortHandler("created_at")}>
                              <span className="sort-titles">Date Submitted</span>
                              <span className={`${this.state.created_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                            </div>
                            <div className="list_item_head appln_date" onClick={() => this.sortHandler("updated_at")}>
                              <span className="sort-titles">Date Approved</span>
                              <span className={`${this.state.order_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
                            </div>
                            
                            <div className="list_item_head"></div>
                          </div>
                          }
                          
                          {selectedUser &&
                            this.generateDocList(documents, 3, true, false, selectedUser.user_type_id === 11 || selectedUser.user_type_id === 12)}
                        </section>
                      )}
                      {/* SELECT DOCUMENT */}
                      {this.state.tab === "new" && (
                          <section className="new">
                            <p>Document</p>
                            <div className="inputs-items">
                              <select
                                className=""
                                name="document"
                                id="document"
                                value={this.state.doc.new}
                                onChange={this.selectHandler}
                                disabled={this.state.loading}
                              >
                                <option value="">-Not Selected-</option>
                                {category_docs &&
                                  category_docs.map((item, index) => (
                                    <option key={index} value={index}>
                                      {item.doc_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <p className="actions">
                              <button disabled={this.state.loading} className="button plain" onClick={() => this.tabChange("list")}>
                                Cancel
                              </button>
                              <button
                                className={`btn button ${this.state.doc.new === "" && "disabled"}`}
                                onClick={this.createDocument}
                                disabled={String(this.state.doc.new) === "" || this.state.doc.new === null || this.state.loading}
                              >
                                Add Document &nbsp;
                                {this.state.loading && (<img
                                  className="loader"
                                  alt="Loader"
                                  style={{ height: "20px" }}
                                  src={require("../assets/images/loader.gif").default}
                                />)}
                              </button>
                            </p>
                          </section>
                      )}
                      {/* DOCUMENT */}
                      {this.state.tab === "document" && (
                        <section className="document merchandise-form">
                          <div className="container doc_info">
                            <p className="button-container doc_info-rh no-border form-top-buttons">
                              <button id="back-button" className="button" onClick={this.viewList}>
                                Back
                              </button>
                              {permissions && permissions.includes("Manage Docs") && this.state.doc.status >= 2 && (
                                <div id="toggle-btn-grp" className="toggle-btn-grp">
                                  <button className="button" onClick={() => this.togglePdf(this.state.pdf_view)}>
                                    {this.state.pdf_view ? "View Document" : "View PDF"}
                                  </button>
                                  <a
                                    id="toggle-btn-download"
                                    className="button"
                                    href={this.createDownloadUrl()}
                                    target="_blank"
                                  >
                                    Download PDF
                                  </a>
                                </div>
                              )}
                            </p>
                          </div>
                          <div className="container doc_info full-width_form">
                            {this.state.pdf_view ? (
                              this.getPdf(this.state.doc)
                            ) : (

                                <React.Fragment>
                                  {this.state?.doc?.slug === "merchandise-order-form" && (
                                    <MerchandiseOrder
                                      viewList={this.viewList}
                                      view_doc={this.state.view_doc}
                                      new_doc_id={this.state.doc.id}
                                      single_doc={true}
                                      tabChange={this.tabChange}
                                      slug={this.state.doc.slug}
                                      adminMode={this.state.adminMode}
                                      mobileMode={this.state.mobile.enabled}
                                      lastPage={true}
                                    />
                                  )}
                                  {this.state?.doc?.slug === "payroll-deduction" && (
                                    <Payroll goBack={() => this.viewList()} isSingleDoc={true} adminMode={this.state.adminMode} docDetails={this.state?.doc}/>
                                  )}
                                  {this.state?.doc?.slug === "time-off-request" && (
                                    <TimeOffRequest 
                                    goBack={() => this.viewList()}  
                                    isSingleDoc={true} 
                                    adminMode={this.state.adminMode} 
                                    docDetails={this.state?.doc} 
                                    status={this.state.doc.tor_docs_status} 
                                    />
                                  )}
                                  {this.state?.doc?.slug === "paycheck-distribution-form" && (
                                    <PaycheckDistribution 
                                      goBack={() => this.viewList()}  
                                      single_doc={true} 
                                      adminMode={this.state.adminMode} 
                                      new_doc_id={this.state.doc.id}
                                      slug={this.state.doc.slug}
                                      lastPage={true}
                                    />
                                  )}
                                  {this.state?.doc?.slug === "employee-information-page" && (
                                    <CandidateInfoPage 
                                      goBack={() => this.viewList()}  
                                      single_doc={true} 
                                      adminMode={this.state.adminMode} 
                                      new_doc_id={this.state.doc.id}
                                      slug={this.state.doc.slug}
                                      lastPage={true}
                                    />
                                  )}
                                     {this.state?.doc?.slug === "beneficiary-form" && (
                                    <BeneficiaryForm 
                                      goBack={() => this.viewList()}  
                                      isSingleDoc={true} 
                                      adminMode={this.state.adminMode} 
                                      new_doc_id={this.state.doc.id}
                                      slug={this.state.doc.slug}
                                      lastPage={true}
                                      doc={this.state.doc}
                                      docs={this.props.docs}
                                    />
                                  )}
                                  {this.state?.doc?.slug === "w-4" && (
                                    <ErrorBoundary>
                                      <W4
                                        goBack={() => this.viewList()}
                                        single_doc={true}
                                        adminMode={this.state.adminMode}
                                        new_doc_id={this.state.doc.id}
                                        slug={this.state.doc.slug}
                                        lastPage={true}
                                      />
                                    </ErrorBoundary>
                                  )}
                                  {this.state?.doc?.slug === "equipment-responsibility-form" && (
                                    <ErrorBoundary>
                                      <EquipmentResponsibilityForm
                                        goBack={() => this.viewList()}
                                        isSingleDoc={true} 
                                        adminMode={this.state.adminMode} 
                                        docDetails={this.state?.doc} 
                                      />
                                    </ErrorBoundary>
                                  )}
                                  {this.state?.doc?.slug === "warning-management" && (
                                    <ErrorBoundary>
                                      <VerbalWarningAck
                                        goBack={() => this.viewList()}
                                        isSingleDoc={true} 
                                        adminMode={this.state.adminMode} 
                                        docDetails={this.state?.doc} 
                                      />
                                    </ErrorBoundary>
                                  )}
                                </React.Fragment>
                            )}
                          </div>
                        </section>
                      )}
                    </section>
                  </Tab.Pane>

                  {/* Interview Forms */}
                  <Tab.Pane eventKey='interviewForms'>
                    <InterviewForm hash={this.props.userHash} listOnly />
                  </Tab.Pane>
                  {/* Certificate Upload */}
                  <Tab.Pane eventKey='certificates'>
                    <Certificates/>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs, chat, permission, user } = state;
  
  return { docs, chat, permission, user, isDocsLoading: docs.isDocsLoading, currentUserPermissions: selectCurrentUserPermissions(state) };
}

let connectedDocList = compose(withRouter, connect(mapStateToProps))(DocsList);
export { connectedDocList as DocsList };

const Loader = () => (
  <div
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <img
      alt="loader"
      className="login_loader"
      src={require("../assets/images/loader.gif").default}
    />
  </div>
);
